import React, { Component } from 'react';
import maskregion from './media/maskregion.png';
import location from './media/location.png';
import mobile from './media/mobile.png';
import email from './media/email.png';
import website from './media/website.png';
import info from './media/info.png';
import products from './media/products.png';
import noproducts from './media/noproducts.png';
import i18n from "i18next";
import Drawer from 'react-drag-drawer';
import './Popupstyle.css';
import { BoxLoading } from 'react-loadingg';

export class Azienda extends Component {


    constructor(props){
        super(props);
        this.state = { business: null, code: props.code, products: [], currentSection: 0, showProductInfo: false, 
          currentProduct: null, currentQuantity: 1, openLoader: false};
        console.log(props.code);
    }

    componentDidMount () {
    
        var sessiontoken = localStorage.getItem('session_token');
       
        if(sessiontoken != null){
            this.getBusinessInfo(sessiontoken);
               
          return;
        }
       
    }


    getBusinessInfo = (token) => {
  
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: token,
            code: this.state.code
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/business/';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         console.log(r);
         if(r.business == null){
            window.location.href = window.location.origin;
         }
          this.setState({ business: r.business, products: r.products });
        }).catch(e => {
           
        });
    
      }

      openAddress = () => {
          //https://www.google.com/maps/place/Via+Torino,+4,+20123+Milano+MI
          
          let newLocation = "https://www.google.com/maps/place/"+this.state.business.address;
          window.open(newLocation, "_blank");
      }

      openWebSite = () => {
        window.open(this.state.business.website, "_blank");
      }

      showProducts = () => {
        this.setState({ currentSection: 0 });
      }

      showBusinessInfo = () => {
        this.setState({ currentSection: 1 });
      }

      openProduct = (index) => {
        this.setState({ showProductInfo: true, currentProduct: index, currentQuantity: 1 });
      }

      closeProductInfo = () => {
        this.setState({ showProductInfo: false });
      }

      addQuantity = () => {
        this.setState({ currentQuantity: this.state.currentQuantity+1});
      }

      removeQuantity = () => {
        if(this.state.currentQuantity>1){
          this.setState({ currentQuantity: this.state.currentQuantity-1});
        }
      }

      addProduct = () => {
        this.setState({ openLoader: true });
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: sessiontoken,
            product: this.state.products[this.state.currentProduct].id,
            quantity: this.state.currentQuantity
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/cart/add';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         this.closeProductInfo();
         this.props.reloadCart();
         this.setState({ openLoader: false });
        }).catch(e => {
           
        });
      }


    render() {
        return (
            <div>
                {this.state.business != null &&
               <div   style={{width: '100%', height: '350px', position: 'relative', }}>
           <div style={{width: '100%', height: 350, left: 0, top: 0, position: 'absolute'}}>
    <img src={this.state.business.image} width="100%" height="350" style={{ objectFit: 'cover', borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px'}} />
    </div>
    <div style={{width: '100%', height: 350, left: 0, top: 0, position: 'absolute'}}>
    <img src={maskregion} width="100%" height="350" style={{ borderBottomRightRadius: '0px', borderBottomLeftRadius: '0px'}} />

    <div style={{width: '100%', height: 25, left: 0, top: 300, position: 'absolute', textAlign: 'center', color: 'white', fontSize: 28,   fontWeight: '800', wordWrap: 'break-word'}}>{this.state.business.name}</div>
    </div>
</div>
    }
 <br />
   
  
    {this.state.business != null &&
    
    <div style={{  width: '50%', margin: 'auto', display: 'flex', justifyContent: 'center'}}>
    {
            this.state.business.categories.map((category, j) =>
            <div key={j} style={{backgroundColor: 'rgb(250,250,250)', margin: '10px', paddingLeft: '15px', paddingRight: '15px', paddingTop: '8px',  paddingBottom: '8px', borderRadius: '8px', textAlign: 'center', color: 'rgb(30,30,30)', fontSize: 14,  fontWeight: '400', wordWrap: 'break-word'}}>{category.name}</div>
    
           )
        }
    </div>
   
    }
    <br /> 

    <div style={{ height: '80px', position: 'relative', backgroundColor: 'rgb(255,255,255)', display: 'flex', justifyContent: 'center', flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'hidden', flexDirection: 'row',scrollBehavior: 'smooth'}}>

    <div onClick={() => this.showProducts()}  style={{  minWidth: '80px', height: '80px', position: 'relative', margin: '0px', cursor: 'pointer' }}>
<div style={{width: 80, height: 30, left: 0, top: 8, position: 'absolute'}}>
<img src={products} width="25px" height="25px" style={{ borderRadius: '0px'}} />
</div>
<div style={{width: 80, height: 40, left: 0, top: 42, position: 'absolute'}}>
{this.state.currentSection == 0 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('products')}</div>
}
{this.state.currentSection != 0 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('products')}</div>
}

</div>
{this.state.currentSection == 0 &&
<div style={{width: 50, height: 2, left: 15, top: 70, position: 'absolute', backgroundColor: 'rgb(50,50,50)'}}>
</div> }
</div>

<div onClick={() => this.showBusinessInfo()}  style={{  minWidth: '80px', height: '80px', position: 'relative', margin: '0px', cursor: 'pointer' }}>
<div style={{width: 80, height: 30, left: 0, top: 8, position: 'absolute'}}>
<img src={info} width="25px" height="25px" style={{ borderRadius: '0px'}} />
</div>
<div style={{width: 80, height: 40, left: 0, top: 42, position: 'absolute'}}>
{this.state.currentSection == 1 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('business.info')}</div>
}
{this.state.currentSection != 1 &&
<div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '400', wordWrap: 'break-word'}}>{i18n.t('business.info')}</div>
}

</div>
{this.state.currentSection == 1 &&
<div style={{width: 50, height: 2, left: 15, top: 70, position: 'absolute', backgroundColor: 'rgb(50,50,50)'}}>
</div> }
</div>


</div>

<br /> 

{(this.state.products.length == 0 && this.state.currentSection==0) &&
<div><br /> {i18n.t('no.products')}<br/><br/>
<img src={noproducts} width="35px" height="35px" style={{ borderRadius: '0px'}} /></div>
}

{(this.state.products.length != 0 && this.state.currentSection==0) &&
 <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  backgroundColor: 'rgb(250,250,250)',
  borderRadius: '8px',
  margin: '20px',
  flexShrink: 1,}}>
  {
        this.state.products.map((product, i) =>
<div key={i} onClick={() => this.openProduct(i)}  style={{ cursor: 'pointer', borderRadius: '8px', width: '330px', minHeight: '70px', margin: '10px', backgroundColor: 'white', position: 'relative' }}>
        <div style={{ display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "stretch",
  flexWrap: 'wrap',
  flexShrink: 1,}}>
<div style={{ width: 100, height: 70, left: 0, top: 0}}>
 <img src={product.image} width="100px" height="70" style={{ objectFit: 'cover', borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }} />
 </div>
 <div style={{}}>
 <div style={{width: 140,  marginLeft: '5px', marginTop: '5px',   textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '500', wordWrap: 'break-word'}}>{product.name}</div>
 <div style={{width: 140,  marginLeft: '5px', marginTop: '5px',   textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 12,  fontWeight: '400', wordWrap: 'break-word'}}>{product.business_name}</div>
</div>
<div style={{ width: 80, backgroundColor: 'white' }}>
                <div style={{ marginTop: '10px', marginLeft: '5px', width: 80, display: 'flex', flexDirection: 'row'}}>
                <div style={{ marginLeft: '30px', marginRight: '20px', color: '#E82E15', fontSize: '20px', fontWeight: '600'}}>+</div>
                </div> 
                <div style={{width: 80, top: 5, marginTop: '10px',  textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 14,  fontWeight: '600', wordWrap: 'break-word'}}>{(Math.round(product.price) / 100).toFixed(2)}€</div>

                </div> 
 </div>
            

</div>
        )
 }
 </div>
}

{(this.state.business != null && this.state.currentSection==1) &&
    

<div style={{ display: 'flex',
     justifyContent: "center",}}>
<div  style={{width: '80%', borderRadius: '10px', backgroundColor: 'rgb(250,250,250)', position: 'relative', }}>
<div style={{width: '80%', margin: 'auto', marginTop: '25px', textAlign: 'center', color: 'rgb(30,30,30)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word'}}>{this.state.business.description}</div>
<br />  
   <div style={{ width: '80%', height: '1.5px', margin: 'auto', backgroundColor: 'rgb(220,220,220)' }} ></div>
<br /> 
{(this.state.business.address != '') &&
<div onClick={this.openAddress} style={{width: '50%', cursor: 'pointer', margin: 'auto', textAlign: 'left', color: 'rgb(30,30,30)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word'}}>
<img src={location} width="15px" height="15px" style={{ verticalAlign: 'bottom' }}  />
  <span style={{ marginLeft: '25px' }}>{this.state.business.address}</span>
</div>
}
{(this.state.business.prefix != '' && this.state.business.mobile != '') &&
<div>
<br />
<a style={{ textDecoration: 'none' }} href={"tel:+"+this.state.business.prefix+this.state.business.mobile}>
<div style={{width: '50%', margin: 'auto', textAlign: 'left', color: 'rgb(30,30,30)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word'}}>
<img src={mobile} width="15px" height="15px" style={{ verticalAlign: 'bottom' }}  />
  <span style={{ marginLeft: '25px' }}>+{this.state.business.prefix} {this.state.business.mobile}</span>
  </div>
  </a>
  </div>
}
{(this.state.business.email != '') &&
<div>
<br />

<a style={{ textDecoration: 'none' }} href={"mailto:"+this.state.business.email}>
<div style={{width: '50%', margin: 'auto', textAlign: 'left', color: 'rgb(30,30,30)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word'}}>
<img src={email} width="15px" height="15px" style={{ verticalAlign: 'bottom' }}  />
  <span style={{ marginLeft: '25px' }}>{this.state.business.email}</span>
  </div>
  </a>
  </div>
}
{(this.state.business.website != '') &&
<div>
<br />
<div onClick={this.openWebSite} style={{ cursor: 'pointer', width: '50%', margin: 'auto', textAlign: 'left', color: 'rgb(30,30,30)', fontSize: 14, fontWeight: '400', wordWrap: 'break-word'}}>
<img src={website} width="15px" height="15px" style={{ verticalAlign: 'bottom' }}  />
  <span style={{ marginLeft: '25px' }}>{this.state.business.website}</span>
  </div>
  </div>
}
<br /> <br /> 
</div>
</div>
  }


  
       

<br /><br />
<br /><br />



<Drawer
                    open={this.state.showProductInfo}
                        onRequestClose={this.closeProductInfo}
                        modalElementClass="modalProduct">
                            <div>
                              {this.state.currentProduct != null &&
    
                              
                            <div  style={{  borderTopRightRadius: '8px',  borderTopLeftRadius: '8px', width: '100%', minHeight: '200px', backgroundColor: 'rgb(250,250,250)', position: 'relative' }}>
        <div style={{ width: '100%', height: 250, left: 0, top: 0}}>
 <img src={this.state.products[this.state.currentProduct].image} width="100%" height="250" style={{ objectFit: 'cover', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}} />
 </div>

 <div style={{width: '96%',  left: 0, margin: '3%',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 18,  fontWeight: '500', wordWrap: 'break-word'}}>{this.state.products[this.state.currentProduct].name}</div>
 <div style={{width: '96%',  left: 0, margin: '3%', marginBottom: '0px',  textAlign: 'left', color: 'rgb(100,100,100)', fontSize: 18,  fontWeight: '400', wordWrap: 'break-word'}}>{(Math.round(this.state.products[this.state.currentProduct].price) / 100).toFixed(2)}€</div>
 <div style={{width: '96%',  left: 0, margin: '3%', marginBottom: '0px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 14,  fontWeight: '400', wordWrap: 'break-word'}}>{this.state.products[this.state.currentProduct].description}</div>
<br/>
</div>
    }

 
                <div>
                <div style={{ marginTop: '15px', width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <div style={{ userSelect: 'none', color: 'rgb(100,100,100)', fontSize: '24px', cursor: 'pointer'}} onClick={this.removeQuantity}>-</div>
                <div style={{ userSelect: 'none', marginLeft: '20px', marginRight: '20px', color: 'black', fontSize: '30px'}}>{this.state.currentQuantity}</div>
                <div style={{ userSelect: 'none', color: 'rgb(100,100,100)', fontSize: '24px', cursor: 'pointer'}} onClick={this.addQuantity}>+</div>
                </div></div>
                
                          
                    <div  style={{marginTop: '0px',  textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <p style={{ cursor: 'pointer',  fontWeight: 'bold', height: '40px', width: '250px', borderRadius: '8px', backgroundColor: '#E82E15', fontSize: '14px', 
            textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white'}}
            onClick={this.addProduct}
            >{i18n.t('add')}</p>
                    </div>
                    <div onClick={this.closeProductInfo} style={{  textAlign: 'center', marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}><span style={{cursor: 'pointer', color: 'black', fontSize: 12, fontWeight: '500', wordWrap: 'break-word'}}>{i18n.t('close')}</span></div>
   
                    </div>
                    </Drawer>

                    <Drawer
                    open={this.state.openLoader}
                        modalElementClass="modalLoader">
                         <div style={{textAlign: 'center', margin: '15px'}}>
            
                         <BoxLoading size="large" color="#E82E15" />
                    </div>
                    </Drawer>

            </div>
        )
    }
}

export default Azienda
/*
<div key={i} onClick={() => this.openProduct(i)}  style={{ borderRadius: '8px', width: '200px', minHeight: '200px', backgroundColor: 'rgb(250,250,250)', position: 'relative', margin: '10px', cursor: 'pointer' }}>
        <div style={{ width: 200, height: 120, left: 0, top: 0}}>
 <img src={product.image} width="200px" height="120" style={{ objectFit: 'cover', borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}} />
 </div>
 <div style={{width: 190,  left: 0, margin: '5px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 14,  fontWeight: '500', wordWrap: 'break-word'}}>{product.name}</div>
 <div style={{width: 220,  left: 0, margin: '5px',  textAlign: 'left', color: 'rgb(100,100,100)', fontSize: 14,  fontWeight: '400', wordWrap: 'break-word'}}>{(Math.round(product.price) / 100).toFixed(2)}€</div>
 <div style={{ marginLeft: '20%', marginRight: '20%', borderRadius: '8px', marginTop: '10px', padding: '5px', marginBottom: '10px', textAlign: 'center', color: 'white', fontSize: 14, backgroundColor: '#E82E15',  fontWeight: '600', wordWrap: 'break-word'}}>{i18n.t('add')}</div>

</div>
*/