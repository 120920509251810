import React, { Component } from 'react';
import maskregion from './media/maskregion.png';
import location from './media/locationwhite.png';
import i18n from "i18next";

export class TopResults extends Component {


    constructor(props){
        super(props);
        this.state = {  categories: [], businesses: [], topBusinesses: [], categoryName: '', selectedCategory: 0};
        console.log(props.code);
    }

    componentDidMount () {
    
        var sessiontoken = localStorage.getItem('session_token');
       
        if(sessiontoken != null){
            this.getTopResultsInfo(sessiontoken);
               
          return;
        }
       
    }

    getTopResultsInfo = (token) => {
  
        const requestOptions = {
          method: 'POST',
          headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          body: JSON.stringify({
            token: token,
          })
          };
        
          const apiUrl = 'https://italiagourmet.cloud/api/public/v1/business/top';
    
        fetch(apiUrl, requestOptions)
        .then(r => r.json())
        .then(r => {
         console.log(r);
         
          this.setState({ categories: r.categories, topBusinesses: r.businesses, businesses: r.businesses, categoryName: i18n.t('all.business') });
        }).catch(e => {
           
        });
    
      }

      openBusinessInfo = (code) => {
        var newLocation = "/azienda/"+code;
        window.location.href = newLocation;
        return;
    }

    showCategoryInfo = (category_index) => {
        if(category_index == 0){
            this.setState({ businesses: this.state.topBusinesses, selectedCategory: this.state.categories[category_index].id });
            return;
          }
        this.setState({ selectedCategory: this.state.categories[category_index].id });
        //add loading spinner
        var sessiontoken = localStorage.getItem('session_token');
        const requestOptions = {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
            }),
            body: JSON.stringify({
              token: sessiontoken,
              category: this.state.categories[category_index].id
            })
            };
          
            const apiUrl = 'https://italiagourmet.cloud/api/public/v1/business/category';
      
          fetch(apiUrl, requestOptions)
          .then(r => r.json())
          .then(r => {
           console.log(r);
            this.setState({ businesses: r.businesses });
          }).catch(e => {
             
          });
    }
    

    render() {
        return (
            <div>
             
             <div style={{ height: '80px', position: 'relative', backgroundColor: 'rgb(255,255,255)', display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll', overflowY: 'hidden', flexDirection: 'row',scrollBehavior: 'smooth'}}>



             {
           this.state.categories.map((category, i) =>
<div key={i} onClick={() => this.showCategoryInfo(i)}  style={{  minWidth: '80px', height: '80px', position: 'relative', margin: '0px', cursor: 'pointer' }}>
           <div style={{width: 80, height: 30, left: 0, top: 8, position: 'absolute'}}>
    <img src={category.icon} width="30px" height="30px" style={{ borderRadius: '0px'}} />
    </div>
    <div style={{width: 80, height: 40, left: 0, top: 42, position: 'absolute'}}>
    {this.state.selectedCategory == category.id &&
    <div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '600', wordWrap: 'break-word'}}>{category.name}</div>
        }
        {this.state.selectedCategory != category.id &&
    <div style={{width: 80, height: 40, position: 'absolute', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 12,   fontWeight: '400', wordWrap: 'break-word'}}>{category.name}</div>
        }
        
    </div>
    {this.state.selectedCategory == category.id &&
        <div style={{width: 50, height: 2, left: 15, top: 70, position: 'absolute', backgroundColor: 'rgb(50,50,50)'}}>
        </div> }
</div>
           )
    }

             </div>

             <div style={{ display: "flex",
     flexDirection: "row",
     justifyContent: "center",
     alignItems: "stretch",
     flexWrap: 'wrap',
     flexShrink: 1,}}>
     {
           this.state.businesses.map((business, i) =>
<div key={i} onClick={() => this.openBusinessInfo(business.code)}  style={{width: '350px', height: '300px', position: 'relative', margin: '10px', cursor: 'pointer' }}>
           <div style={{width: 250, height: 200, left: 0, top: 0}}>
    <img src={business.image} width="350px" height="200" style={{ objectFit: 'cover', borderRadius: '8px'}} />
    </div>
    <div style={{width: 250, height: 150, left: 0, top: 0, position: 'absolute'}}>
    <img src={maskregion} width="350px" height="200" style={{ borderRadius: '8px'}} />
    
    <div style={{width: 180, height: 30, left: 10, top: 170, position: 'absolute', textAlign: 'left', color: 'white', fontSize: 16,   fontWeight: '400', wordWrap: 'break-word'}}>
    <img src={location} width="15px" height="15px" style={{ verticalAlign: 'center', marginRight: '5px' }}  />{business.region_name}</div>
    
    </div>
    <div style={{width: 350,  left: 0, margin: '7px',  textAlign: 'left', color: 'rgb(50,50,50)', fontSize: 16,  fontWeight: '400', wordWrap: 'break-word'}}>{business.name}</div>
    <div style={{width: 350, left: 0, display: 'flex', alignItems: 'left', alignContent: 'left', textAlign: 'left', verticalAlign: 'top'}}>
    {
            business.categories.map((category, j) =>
            <div key={j} style={{backgroundColor: 'rgb(245,245,245)', margin: '7px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px',  paddingBottom: '5px', borderRadius: '10px', textAlign: 'center', color: 'rgb(50,50,50)', fontSize: 14,  fontWeight: '400', wordWrap: 'break-word'}}>{category.name}</div>
    
           )
        }
    </div>
</div>
           )
    }
    </div>

   

            </div>
        )
    }
}

export default TopResults
